/*------------------------------------*\
		Footer
\*------------------------------------*/

.main-footer {

}

.footer_infos {
	margin-top: 2rem;
  border-top: 1px solid $grey-medium;

  @media #{md('smaller')} {
    margin-top: 4rem;
  }

  @media #{md('small')} {
    margin-top: 8.4375rem;
  }
}
