/*---------------------------------------*\
		Sélection
\*---------------------------------------*/

::-moz-selection {
	text-shadow: none;
	color: $selection-color;
	background: $selection-bg;
}

::selection {
	text-shadow: none;
	color: $selection-color;
	background: $selection-bg;
}




/*---------------------------------------*\
		Réglages globaux
\*---------------------------------------*/

*,
*:after,
*:before {
	position: relative;
	box-sizing: border-box;
	outline: none;
}

html {
  font-family: $font-grotesk;
  font-size: $font-size;
  line-height: 2em;

	// Better font rendering for mac
	&.mac {
		@include antialiased;
	}

	&.aside-is-active {
		overflow: hidden;
	}
}

body {
	font-family: $font-grotesk;
	font-size: 1em;
	color: $font-color;
}

a {
	color: currentColor;
	text-decoration: none;

	&:hover {
		color: $blue-medium;
	}
}

.block-overflow {
	transition: transform 0.3s;

	@media #{md("medium")} {

		.aside-is-active & {
			transform: translateX(-100px);
		}
	}
}



/*------------------------------------*\
		Signature Studio Meta
\*------------------------------------*/

.studio-meta {
	display: inline-block;
	font-size: 14px;
	font-family: $font-grotesk;
	color: $black;
	text-decoration: none;
	overflow: hidden;

	&:hover {

		.icon_hover {
			width: 100%;
		}
	}

	span {
		display: inline-block;
	}
}


/*------------------------------------*\
		Cookies
\*------------------------------------*/
.cookies {
	z-index: z("modal");
	position: fixed;
	bottom: 0;
	left: 0;
	display: none;
	width: 100%;
	padding: 1em 0.5em;
	text-align: center;

	background-color: #fff;
	visibility: hidden;

	&.is-visible {
		display: block;
		visibility: visible;
	}
}

.cookies-desc {
	margin-bottom: 0;
}

.cookies-btn {
	display: inline-block;
	width: auto;
	margin-left: 0.5em;
	padding: 0.2em 0.5em;
	color: #fff;
}
