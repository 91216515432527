/*==========================================================================*\
  Field
\*==========================================================================*/

/* Field - Label
\*==========================================================================*/

.field-label {
  display: inline-block;
  font-size: 0.75em;
  line-height: 1.833333333em;
  cursor: pointer;
}

/* Field - Group
\*==========================================================================*/

.field-group {
  display: block;

  .field-label {
    display: block;
    margin-bottom: 0.75em;
  }

  &.is-valid,
  &.is-fail {
    .field-text {
      padding-right: 4em;
    }
  }

  &.is-valid {
    .field-text {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16.3 13.7'%3E%3Cpolygon fill='green' points='0,7.2 0.8,6.5 5.4,12.1 15.5,0 16.3,0.6 5.4,13.7 '/%3E%3C/svg%3E%0A");
      border-color: $green-notice;
    }
  }

  &.is-fail {
    .field-text {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12.8 12.8'%3E%3Cpath fill='red' d='M12,0l0.7,0.7l-12,12L0,12L12,0z M0.7,0l12,12L12,12.7L0,0.7L0.7,0z'/%3E%3C/svg%3E%0A");
      border-color: $red-notice;
    }
  }
}

// Bottom marge
.field-group--bottom-marge {
  margin-bottom: 1em;
}

/* Field - Text
\*==========================================================================*/

.field-text {
  width: 100%;
  padding: 1.322em 1.6em;
  font-size: 0.875em;
  line-height: 1em;
  background-color: $white;
  background-repeat: no-repeat;
  background-position: right 1.6em center;
  background-size: 16px;
  border: 1px solid $grey-medium;
  border-radius: 0;
  transition: border-color 0.6s $out-expo;

  &::placeholder {
    color: $grey;
  }

  &:hover {
    border-color: #000;
  }

  &:focus {
    border-color: #777;
    outline: 0;
    box-shadow: 0 0 0 2px #eee;
  }
}

/* Field - Radio
\*==========================================================================*/

.field-group-radio {
  display: inline-block;
  vertical-align: middle;

  &:not(:last-child) {
    margin-right: 2em;
  }

  .field-label {
    left: 0.25em;
    display: inline-block;
    margin-bottom: 0;
    padding-left: 20px;
    vertical-align: middle;

    &:before,
    &:after {
      content: '';
      position: absolute;
    }

    &:before {
      top: 3px;
      left: 0;
      width: 15px;
      height: 15px;
      border: 1px solid $black;
      border-radius: 50%;
    }

    &:after {
      top: 6px;
      left: 3px;
      width: 9px;
      height: 9px;
      background: $black;
      border-radius: 50%;
      opacity: 0;
      transform: scale(0.5);
      transition: opacity 0.1s, transform 0.1s;
    }
  }

  input {
    position: absolute;
    left: -9999px;

    &:checked {
      + .field-label {
        &:after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}

/* Field - Checkbox
\*==========================================================================*/

.field-group-checkbox {
  display: inline-block !important;
  vertical-align: middle;

  &:not(:last-child) {
    margin-right: 2em;
  }

  .field-label {
    left: 0.25em;
    display: inline-block;
    margin-bottom: 0;
    padding-left: 20px;
    text-transform: none;
    vertical-align: middle;
    cursor: pointer;

    &:before,
    &:after {
      content: '';
      position: absolute;
    }

    &:before {
      top: 3px;
      left: 0;
      width: 15px;
      height: 15px;
      border: 1px solid $black;
    }

    &:after {
      top: 6px;
      left: 3px;
      width: 9px;
      height: 9px;
      background: $black;
      opacity: 0;
      transform: scale(0.5);
      transition: opacity 0.1s, transform 0.1s;
    }
  }

  input {
    position: absolute;
    left: -9999px;

    &:checked {
      + .field-label {
        &:after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}

