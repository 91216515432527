/*------------------------------------*\
		Hero
\*------------------------------------*/

.hero {
  width: 100%;
  height: 20rem;
  color: $white;

  @media #{md("small")} {
    height: 42rem;
  }

  @media #{md("large")} {
    height: 46rem;
  }
}

.hero__background--placeholder {
  transition: opacity 3s;
}

.hero__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $grey-medium;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 23%,
      rgba(0, 0, 0, 1) 69%,
      rgba(0, 0, 0, 1) 100%
    );
    opacity: 0;
  }

  &.js-lazy {
    &.is-lazy-loaded {
      &:before {
        opacity: 0.75;
      }

      ~ .hero__background--placeholder {
        opacity: 0;
      }
    }
  }

  @media #{md('large')} {
    &:before {
      background-image: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 23%,
        rgba(0, 0, 0, 1) 69%,
        rgba(0, 0, 0, 1) 100%
      );
    }
  }
}

.hero__container {
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}

.hero__row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;

  @media #{md('large')} {
    align-items: center;
  }
}

.hero__col {
  margin-bottom: 1em;

  @media #{md('small')} {
    margin-bottom: 6em;
  }

  @media #{md('large')} {
    margin-bottom: 0;
  }
}

.hero__surtitle {
  margin-top: 0;
  margin-bottom: 1em;
  font-family: $font-family;
  font-size: 0.75em;
  line-height: 1.833333333em;
  text-transform: uppercase;
  letter-spacing: $letter-spacing-200;
}

.hero__title {
  margin-top: 0;
  margin-bottom: 0;
  font-family: $font-paradox;
  font-size: 1.5em;
  font-weight: 400;
  line-height: 1.391304348em;

  @media #{md('small')} {
    font-size: 1.75em;
  }

  @media #{md('medium')} {
    font-size: 2em;
  }

  @media #{md('large')} {
    font-size: 2.25em;
  }

  @media #{md('larger')} {
    font-size: 2.875em;
  }
}

.hero__content {
  margin-top: 1em;
  margin-bottom: 0;

  p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
