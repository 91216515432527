@charset "UTF-8";
/*---------------------------------------*\
		Styles et réglages globaux
\*---------------------------------------*/
/*------------------------------------*\
		Easing
\*------------------------------------*/
/*------------------------------------*\
		CSS Reset

	http://meyerweb.com/eric/tools/css/reset/
  v2.0 | 20110126
  License: none (public domain)
\*------------------------------------*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video,
button, input, select {
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  font: inherit;
  font-size: 100%;
  border: 0;
}

/* HTML5 display-role reset for older browsers */
main,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

q,
blockquote {
  quotes: none;
}
q:after, q:before,
blockquote:after,
blockquote:before {
  content: "";
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button,
input {
  font: inherit;
}
.mac button,
.mac input {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-osx-font-smoothing: antialiased;
}

button {
  cursor: pointer;
}

/*------------------------------------*\
		Functions
\*------------------------------------*/
/**
 * A function helper to avoid having to type `map-get($z-layers, ...)`
 * Based on http://css-tricks.com/handling-z-index/
 * @param  {string} $layer The name of the z-index
 * @param  {number} $var   The modifier if needed
 * @return {number}        The corresponding z-index based on the $z-layers var
 */
/**
 * Remove units from the given number
 * @param  {number} $number The number with units
 * @return {number}
 */
/**
 * Create variables for the media queries
 * @param  {string} $breakpoint The breakpoint
 * @param  {string} $type       Type of media query (min or max)
 * @param  {string} $unit       The unit for the media queries (em or px)
 * @return {string}             A media query expression
 */
/**
 * Slightly lighten a color
 * @access public
 * @param {Color} $color - color to tint
 * @param {Number} $percentage - percentage of `$color` in returned color
 * @return {Color}
 */
/**
 * Slightly darken a color
 * @access public
 * @param  {color}  $color       color to shade
 * @param  {number} $percentage  percentage of `$color` in returned color
 * @return {Color}
 */
/**
 * Power function
 * @param  {number} $number Number to apply power
 * @param  {number} $exp    The exponant for the power
 * @return {number}         The powered number
 */
/**
 * Factorial function
 * @param  {number} $number The number to factorize
 * @return {number}         The factorised number
 */
/**
 * Pi reference
 * @return {number} Retrun PI with 11 decimals
 */
/**
 * Convert deg to rad
 * @param  {string} $angle The angle to convert
 * @return {number}        The unitless angle converted to rad
 */
/**
 * Calculate the sinus of an angle
 * @param  {string} $angle The angle to compute
 * @return {number}        The sinus of the given angle
 */
/**
 * Calculate the cosinus of an angle
 * @param  {string} $angle The angle to compute
 * @return {number}        The cosinus of the given angle
 */
/**
 * Calculate the tangent of an angle
 * @param  {string} $angle The angle to compute
 * @return {number}        The tangent of the given angle
 */
/*---------------------------------------*\
		Positions & dispositions
\*---------------------------------------*/
/*---------------------------------------*\
		Typographie
\*---------------------------------------*/
/*---------------------------------------*\
		Triangles
\*---------------------------------------*/
/*------------------------------------*\
		Gradients
\*------------------------------------*/
/**
 * Mixin printing a linear-gradient
 * as well as a plain color fallback
 * and the `-webkit-` prefixed declaration
 * @param {String | List | Angle} $direction - Linear gradient direction
 * @param {Arglist} $color-stops - List of color-stops composing the gradient
 */
/*------------------------------------*\
		Fontface declaration
\*------------------------------------*/
@font-face {
  font-family: "paradox-st";
  src: url("/assets/fonts/paradox-st.woff") format("woff");
  src: url("/assets/fonts/paradox-st.woff2") format("woff2"), url("/assets/fonts/paradox-st.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "urwgrotesk-lig";
  src: url("/assets/fonts/urwgrotesk-lig.woff") format("woff");
  src: url("/assets/fonts/urwgrotesk-lig.woff2") format("woff2"), url("/assets/fonts/urwgrotesk-lig.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
/*---------------------------------------*\
		Debug
\*---------------------------------------*/
/*
Une condition dépendante de la variable `$debug` qui permet d'afficher les contours de tous les élements du DOM.
*/
[debug] {
  display: none;
}

/*------------------------------------*\
		Positionnement & Display
\*------------------------------------*/
.none {
  display: none;
}

.table {
  display: table;
}

.cell {
  display: table-cell;
}

.inlbl {
  display: inline-block;
}

/* Hidden accessible
\*==========================================================================*/
.hidden-accessible {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
}

.abs {
  position: absolute;
}

.rel {
  position: relative;
}

.cf:after {
  content: "";
  clear: both;
  display: block;
}

.left {
  float: left;
}

.right {
  float: right;
}

/*------------------------------------*\
		Typographie
\*------------------------------------*/
.tac {
  text-align: center;
}

.tal {
  text-align: left;
}

.tar {
  text-align: right;
}

.list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-osx-font-smoothing: antialiased;
}

.hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

/*---------------------------------------*\
		Sélection
\*---------------------------------------*/
::-moz-selection {
  text-shadow: none;
  color: #fff;
  background: #222222;
}

::selection {
  text-shadow: none;
  color: #fff;
  background: #222222;
}

/*---------------------------------------*\
		Réglages globaux
\*---------------------------------------*/
*,
*:after,
*:before {
  position: relative;
  box-sizing: border-box;
  outline: none;
}

html {
  font-family: "urwgrotesk-lig", "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  line-height: 2em;
}
html.mac {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-osx-font-smoothing: antialiased;
}
html.aside-is-active {
  overflow: hidden;
}

body {
  font-family: "urwgrotesk-lig", "Helvetica Neue", Arial, sans-serif;
  font-size: 1em;
  color: #010101;
}

a {
  color: currentColor;
  text-decoration: none;
}
a:hover {
  color: #3b3e69;
}

.block-overflow {
  transition: transform 0.3s;
}
@media (min-width: 62em) {
  .aside-is-active .block-overflow {
    transform: translateX(-100px);
  }
}

/*------------------------------------*\
		Signature Studio Meta
\*------------------------------------*/
.studio-meta {
  display: inline-block;
  font-size: 14px;
  font-family: "urwgrotesk-lig", "Helvetica Neue", Arial, sans-serif;
  color: #010101;
  text-decoration: none;
  overflow: hidden;
}
.studio-meta:hover .icon_hover {
  width: 100%;
}
.studio-meta span {
  display: inline-block;
}

/*------------------------------------*\
		Cookies
\*------------------------------------*/
.cookies {
  z-index: 200;
  position: fixed;
  bottom: 0;
  left: 0;
  display: none;
  width: 100%;
  padding: 1em 0.5em;
  text-align: center;
  background-color: #fff;
  visibility: hidden;
}
.cookies.is-visible {
  display: block;
  visibility: visible;
}

.cookies-desc {
  margin-bottom: 0;
}

.cookies-btn {
  display: inline-block;
  width: auto;
  margin-left: 0.5em;
  padding: 0.2em 0.5em;
  color: #fff;
}

/*
Typographie
===========

Règles de base pour tout le site (h1, h2, blockquote, etc).

-->    http://type-scale.com/    <--

Pratique pour générer des rythmes typographiques rapidement
*/
p,
ul,
ol,
blockquote {
  margin-bottom: 1.3em;
}

h1,
h2,
h3,
h4 {
  margin: 1.414em 0 0.5em;
  font-family: "paradox-st", Georgia, serif;
  font-weight: 400;
  line-height: 1.2;
}

h1 {
  margin-top: 0;
  font-size: 3.157em;
}

h2 {
  font-size: 2.369em;
}

h3 {
  font-size: 1.777em;
}

h4 {
  font-size: 1.333em;
}

small {
  font-size: 0.75em;
}

sup {
  font-size: 0.5em;
  vertical-align: super;
}

a {
  text-decoration-skip: ink;
}

/*------------------------------------*\
		Grid system (Bootstrap)
\*------------------------------------*/
.container {
  width: calc(100% - 0.5em);
  max-width: calc(99em + 1em);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 48em) {
  .container {
    width: calc(100% - 0.625em);
    max-width: calc(99em + 1.25em);
  }
}
@media (min-width: 62em) {
  .container {
    width: calc(100% - 1.5em);
    max-width: calc(99em + 3em);
  }
}

.container--without-padding {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.row {
  margin-right: -0.5em;
  margin-left: -0.5em;
}
.row:after {
  content: "";
  clear: both;
  display: block;
}
@media (min-width: 48em) {
  .row {
    margin-right: -0.625em;
    margin-left: -0.625em;
  }
}
@media (min-width: 62em) {
  .row {
    margin-right: -1.5em;
    margin-left: -1.5em;
  }
}

.container,
[class*=col-xs-],
[class*=col-s-],
[class*=col-m-],
[class*=col-n-],
[class*=col-l-],
[class*=col-xl-] {
  min-height: 1px;
  padding-right: 0.5em;
  padding-left: 0.5em;
}
@media (min-width: 48em) {
  .container,
[class*=col-xs-],
[class*=col-s-],
[class*=col-m-],
[class*=col-n-],
[class*=col-l-],
[class*=col-xl-] {
    padding-right: 0.625em;
    padding-left: 0.625em;
  }
}
@media (min-width: 62em) {
  .container,
[class*=col-xs-],
[class*=col-s-],
[class*=col-m-],
[class*=col-n-],
[class*=col-l-],
[class*=col-xl-] {
    padding-right: 1.5em;
    padding-left: 1.5em;
  }
}

[class*=col-xs-],
[class*=col-s-],
[class*=col-m-],
[class*=col-n-],
[class*=col-l-],
[class*=col-xl-] {
  float: left;
}
[class*=col-xs-].right,
[class*=col-s-].right,
[class*=col-m-].right,
[class*=col-n-].right,
[class*=col-l-].right,
[class*=col-xl-].right {
  float: right;
}
[class*=col-xs-].center,
[class*=col-s-].center,
[class*=col-m-].center,
[class*=col-n-].center,
[class*=col-l-].center,
[class*=col-xl-].center {
  float: none;
  margin-right: auto;
  margin-left: auto;
}

.container--without-padding-xs {
  padding-right: 0;
  padding-left: 0;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-11 {
  width: 91.66666667%;
}

.col-xs-10 {
  width: 83.33333333%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-8 {
  width: 66.66666667%;
}

.col-xs-7 {
  width: 58.33333333%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-5 {
  width: 41.66666667%;
}

.col-xs-4 {
  width: 33.33333333%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-2 {
  width: 16.66666667%;
}

.col-xs-1 {
  width: 8.33333333%;
}

.col-xs-0 {
  width: 0;
}

@media (min-width: 30em) {
  .container--without-padding-s {
    padding-right: 0;
    padding-left: 0;
  }

  .col-s-12 {
    width: 100%;
  }

  .col-s-11 {
    width: 91.66666667%;
  }

  .col-s-10 {
    width: 83.33333333%;
  }

  .col-s-9 {
    width: 75%;
  }

  .col-s-8 {
    width: 66.66666667%;
  }

  .col-s-7 {
    width: 58.33333333%;
  }

  .col-s-6 {
    width: 50%;
  }

  .col-s-5 {
    width: 41.66666667%;
  }

  .col-s-4 {
    width: 33.33333333%;
  }

  .col-s-3 {
    width: 25%;
  }

  .col-s-2 {
    width: 16.66666667%;
  }

  .col-s-1 {
    width: 8.33333333%;
  }

  .col-s-0 {
    width: 0;
  }
}
@media (min-width: 48em) {
  .container--without-padding-m {
    padding-right: 0;
    padding-left: 0;
  }

  .col-m-12 {
    width: 100%;
  }

  .col-m-11 {
    width: 91.66666667%;
  }

  .col-m-10 {
    width: 83.33333333%;
  }

  .col-m-9 {
    width: 75%;
  }

  .col-m-8 {
    width: 66.66666667%;
  }

  .col-m-7 {
    width: 58.33333333%;
  }

  .col-m-6 {
    width: 50%;
  }

  .col-m-5 {
    width: 41.66666667%;
  }

  .col-m-4 {
    width: 33.33333333%;
  }

  .col-m-3 {
    width: 25%;
  }

  .col-m-2 {
    width: 16.66666667%;
  }

  .col-m-1 {
    width: 8.33333333%;
  }

  .col-m-0 {
    width: 0;
  }
}
@media (min-width: 62em) {
  .container--without-padding-n {
    padding-right: 0;
    padding-left: 0;
  }

  .col-n-12 {
    width: 100%;
  }

  .col-n-11 {
    width: 91.66666667%;
  }

  .col-n-10 {
    width: 83.33333333%;
  }

  .col-n-9 {
    width: 75%;
  }

  .col-n-8 {
    width: 66.66666667%;
  }

  .col-n-7 {
    width: 58.33333333%;
  }

  .col-n-6 {
    width: 50%;
  }

  .col-n-5 {
    width: 41.66666667%;
  }

  .col-n-4 {
    width: 33.33333333%;
  }

  .col-n-3 {
    width: 25%;
  }

  .col-n-2 {
    width: 16.66666667%;
  }

  .col-n-1 {
    width: 8.33333333%;
  }

  .col-n-0 {
    width: 0;
  }
}
@media (min-width: 80em) {
  .container--without-padding-l {
    padding-right: 0;
    padding-left: 0;
  }

  .col-l-12 {
    width: 100%;
  }

  .col-l-11 {
    width: 91.66666667%;
  }

  .col-l-10 {
    width: 83.33333333%;
  }

  .col-l-9 {
    width: 75%;
  }

  .col-l-8 {
    width: 66.66666667%;
  }

  .col-l-7 {
    width: 58.33333333%;
  }

  .col-l-6 {
    width: 50%;
  }

  .col-l-5 {
    width: 41.66666667%;
  }

  .col-l-4 {
    width: 33.33333333%;
  }

  .col-l-3 {
    width: 25%;
  }

  .col-l-2 {
    width: 16.66666667%;
  }

  .col-l-1 {
    width: 8.33333333%;
  }

  .col-l-0 {
    width: 0;
  }
}
@media (min-width: 90em) {
  .container--without-padding-xl {
    padding-right: 0;
    padding-left: 0;
  }

  .col-xl-12 {
    width: 100%;
  }

  .col-xl-11 {
    width: 91.66666667%;
  }

  .col-xl-10 {
    width: 83.33333333%;
  }

  .col-xl-9 {
    width: 75%;
  }

  .col-xl-8 {
    width: 66.66666667%;
  }

  .col-xl-7 {
    width: 58.33333333%;
  }

  .col-xl-6 {
    width: 50%;
  }

  .col-xl-5 {
    width: 41.66666667%;
  }

  .col-xl-4 {
    width: 33.33333333%;
  }

  .col-xl-3 {
    width: 25%;
  }

  .col-xl-2 {
    width: 16.66666667%;
  }

  .col-xl-1 {
    width: 8.33333333%;
  }

  .col-xl-0 {
    width: 0;
  }
}
@media (min-width: 120em) {
  .container--without-padding-xxl {
    padding-right: 0;
    padding-left: 0;
  }

  .col-xxl-12 {
    width: 100%;
  }

  .col-xxl-11 {
    width: 91.66666667%;
  }

  .col-xxl-10 {
    width: 83.33333333%;
  }

  .col-xxl-9 {
    width: 75%;
  }

  .col-xxl-8 {
    width: 66.66666667%;
  }

  .col-xxl-7 {
    width: 58.33333333%;
  }

  .col-xxl-6 {
    width: 50%;
  }

  .col-xxl-5 {
    width: 41.66666667%;
  }

  .col-xxl-4 {
    width: 33.33333333%;
  }

  .col-xxl-3 {
    width: 25%;
  }

  .col-xxl-2 {
    width: 16.66666667%;
  }

  .col-xxl-1 {
    width: 8.33333333%;
  }

  .col-xxl-0 {
    width: 0;
  }
}
/*------------------------------------*\
		Header
\*------------------------------------*/
.header {
  padding-top: 1em;
  padding-bottom: 1em;
}
@media (min-width: 64em) {
  .header {
    padding-top: 1.53em;
    padding-bottom: 1.53em;
  }
}

@media (min-width: 80em) {
  .header__row {
    display: flex;
    align-items: center;
  }
}

.header__col--title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.header-title__item {
  display: block;
  font-size: 0.625em;
  line-height: 1.375em;
  text-transform: uppercase;
  letter-spacing: 0.404ch;
}
@media (min-width: 30em) {
  .header-title__item {
    display: inline-block;
  }
}

.header-title__item--divider {
  display: block;
  width: 100%;
  height: 0.25em;
}
@media (min-width: 30em) {
  .header-title__item--divider {
    display: inline-block;
    width: 1px;
    height: 1rem;
    margin-right: 2em;
    margin-left: 2em;
    background-color: #cccccc;
  }
}

.header__col--logo {
  margin-top: 1.5em;
  line-height: 0;
  text-align: center;
}
@media (min-width: 80em) {
  .header__col--logo {
    margin-top: 0;
  }
}

.header__lang {
  display: flex;
  align-items: center;
  line-height: 1em;
}
.header__lang a {
  font-size: 0.625em;
  line-height: 1em;
  color: #aaaaaa;
  text-transform: uppercase;
  letter-spacing: 0.404ch;
}
.header__lang a.is-active {
  color: #222222;
}
.header__lang a.is-active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -0.5em;
  width: 100%;
  height: 1px;
  background-color: currentColor;
}
.header__lang a:not(:last-child) {
  margin-right: 1em;
}

.header__lang--mobile {
  width: 100%;
  justify-content: flex-end;
}
@media (min-width: 30em) {
  .header__lang--mobile {
    width: auto;
    margin-left: auto;
    padding-left: 1em;
    justify-content: flex-start;
  }
}
@media (min-width: 80em) {
  .header__lang--mobile {
    display: none;
  }
}

.header__lang--desktop {
  display: none;
  justify-content: flex-end;
}
@media (min-width: 80em) {
  .header__lang--desktop {
    display: flex;
  }
}

/*------------------------------------*\
		Footer
\*------------------------------------*/
.footer_infos {
  margin-top: 2rem;
  border-top: 1px solid #cccccc;
}
@media (min-width: 30em) {
  .footer_infos {
    margin-top: 4rem;
  }
}
@media (min-width: 48em) {
  .footer_infos {
    margin-top: 8.4375rem;
  }
}

/*==========================================================================*\
  Aside
\*==========================================================================*/
/*---------------------------------------*\
		Styles et réglages globaux
\*---------------------------------------*/
/*------------------------------------*\
		Easing
\*------------------------------------*/
/*------------------------------------*\
		Functions
\*------------------------------------*/
/**
 * A function helper to avoid having to type `map-get($z-layers, ...)`
 * Based on http://css-tricks.com/handling-z-index/
 * @param  {string} $layer The name of the z-index
 * @param  {number} $var   The modifier if needed
 * @return {number}        The corresponding z-index based on the $z-layers var
 */
/**
 * Remove units from the given number
 * @param  {number} $number The number with units
 * @return {number}
 */
/**
 * Create variables for the media queries
 * @param  {string} $breakpoint The breakpoint
 * @param  {string} $type       Type of media query (min or max)
 * @param  {string} $unit       The unit for the media queries (em or px)
 * @return {string}             A media query expression
 */
/**
 * Slightly lighten a color
 * @access public
 * @param {Color} $color - color to tint
 * @param {Number} $percentage - percentage of `$color` in returned color
 * @return {Color}
 */
/**
 * Slightly darken a color
 * @access public
 * @param  {color}  $color       color to shade
 * @param  {number} $percentage  percentage of `$color` in returned color
 * @return {Color}
 */
/**
 * Power function
 * @param  {number} $number Number to apply power
 * @param  {number} $exp    The exponant for the power
 * @return {number}         The powered number
 */
/**
 * Factorial function
 * @param  {number} $number The number to factorize
 * @return {number}         The factorised number
 */
/**
 * Pi reference
 * @return {number} Retrun PI with 11 decimals
 */
/**
 * Convert deg to rad
 * @param  {string} $angle The angle to convert
 * @return {number}        The unitless angle converted to rad
 */
/**
 * Calculate the sinus of an angle
 * @param  {string} $angle The angle to compute
 * @return {number}        The sinus of the given angle
 */
/**
 * Calculate the cosinus of an angle
 * @param  {string} $angle The angle to compute
 * @return {number}        The cosinus of the given angle
 */
/**
 * Calculate the tangent of an angle
 * @param  {string} $angle The angle to compute
 * @return {number}        The tangent of the given angle
 */
/* Main
\*==========================================================================*/
.main-aside {
  z-index: 9000;
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 50px);
  height: 100%;
  overflow: auto;
  background: #fff;
  transform: translateX(100%);
  transition: transform 0.4s;
}
.main-aside.is-open {
  transform: translateX(0);
}
.main-aside.is-open + .aside-background-close {
  height: 100%;
  opacity: 0.8;
  transition: height 0s 0s, opacity 0.4s 0s;
}
@media (min-width: 48em) {
  .main-aside {
    width: 75%;
  }
}
@media (min-width: 64em) {
  .main-aside {
    width: 50%;
  }
}
@media (min-width: 80em) {
  .main-aside {
    width: 40%;
  }
}

.aside-close {
  z-index: 1;
  position: absolute;
  top: 1em;
  right: 1em;
  line-height: 0;
  color: #010101;
  cursor: pointer;
  background: #fff;
}
.aside-close:hover {
  background: transparent;
}

.aside-background-close {
  z-index: 202;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  cursor: pointer;
  background: #000;
  opacity: 0;
  transition: height 0s 0.4s, opacity 0.4s 0s;
}

.main-aside-temp {
  display: none;
}

/*------------------------------------*\
		Buttons
\*------------------------------------*/
.btn {
  padding: 1.042em 1.75em;
  overflow: hidden;
  font-family: "urwgrotesk-lig", "Helvetica Neue", Arial, sans-serif;
  font-size: 0.75em;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  cursor: pointer;
  background: #010101;
  border-radius: 0;
  transition: background 0.1s ease-in-out;
}
.btn:hover, .btn:focus, .btn:active {
  background: #222222;
  transition: background 0.1s ease-in-out;
}
.is-waiting .btn {
  background: #999;
  transition: background 0.1s ease-in-out;
}
.is-waiting .btn:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 33%;
  height: 5px;
  background: #f5f6f8;
  animation: btnWaiting 0.75s linear infinite;
}

@keyframes btnWaiting {
  from {
    left: -33%;
  }
  to {
    left: 133%;
  }
}
/*==========================================================================*\
  Field
\*==========================================================================*/
/* Field - Label
\*==========================================================================*/
.field-label {
  display: inline-block;
  font-size: 0.75em;
  line-height: 1.833333333em;
  cursor: pointer;
}

/* Field - Group
\*==========================================================================*/
.field-group {
  display: block;
}
.field-group .field-label {
  display: block;
  margin-bottom: 0.75em;
}
.field-group.is-valid .field-text, .field-group.is-fail .field-text {
  padding-right: 4em;
}
.field-group.is-valid .field-text {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16.3 13.7'%3E%3Cpolygon fill='green' points='0,7.2 0.8,6.5 5.4,12.1 15.5,0 16.3,0.6 5.4,13.7 '/%3E%3C/svg%3E%0A");
  border-color: #46a74e;
}
.field-group.is-fail .field-text {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12.8 12.8'%3E%3Cpath fill='red' d='M12,0l0.7,0.7l-12,12L0,12L12,0z M0.7,0l12,12L12,12.7L0,0.7L0.7,0z'/%3E%3C/svg%3E%0A");
  border-color: #f13340;
}

.field-group--bottom-marge {
  margin-bottom: 1em;
}

/* Field - Text
\*==========================================================================*/
.field-text {
  width: 100%;
  padding: 1.322em 1.6em;
  font-size: 0.875em;
  line-height: 1em;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: right 1.6em center;
  background-size: 16px;
  border: 1px solid #cccccc;
  border-radius: 0;
  transition: border-color 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}
.field-text::placeholder {
  color: #aaaaaa;
}
.field-text:hover {
  border-color: #000;
}
.field-text:focus {
  border-color: #777;
  outline: 0;
  box-shadow: 0 0 0 2px #eee;
}

/* Field - Radio
\*==========================================================================*/
.field-group-radio {
  display: inline-block;
  vertical-align: middle;
}
.field-group-radio:not(:last-child) {
  margin-right: 2em;
}
.field-group-radio .field-label {
  left: 0.25em;
  display: inline-block;
  margin-bottom: 0;
  padding-left: 20px;
  vertical-align: middle;
}
.field-group-radio .field-label:before, .field-group-radio .field-label:after {
  content: "";
  position: absolute;
}
.field-group-radio .field-label:before {
  top: 3px;
  left: 0;
  width: 15px;
  height: 15px;
  border: 1px solid #010101;
  border-radius: 50%;
}
.field-group-radio .field-label:after {
  top: 6px;
  left: 3px;
  width: 9px;
  height: 9px;
  background: #010101;
  border-radius: 50%;
  opacity: 0;
  transform: scale(0.5);
  transition: opacity 0.1s, transform 0.1s;
}
.field-group-radio input {
  position: absolute;
  left: -9999px;
}
.field-group-radio input:checked + .field-label:after {
  opacity: 1;
  transform: scale(1);
}

/* Field - Checkbox
\*==========================================================================*/
.field-group-checkbox {
  display: inline-block !important;
  vertical-align: middle;
}
.field-group-checkbox:not(:last-child) {
  margin-right: 2em;
}
.field-group-checkbox .field-label {
  left: 0.25em;
  display: inline-block;
  margin-bottom: 0;
  padding-left: 20px;
  text-transform: none;
  vertical-align: middle;
  cursor: pointer;
}
.field-group-checkbox .field-label:before, .field-group-checkbox .field-label:after {
  content: "";
  position: absolute;
}
.field-group-checkbox .field-label:before {
  top: 3px;
  left: 0;
  width: 15px;
  height: 15px;
  border: 1px solid #010101;
}
.field-group-checkbox .field-label:after {
  top: 6px;
  left: 3px;
  width: 9px;
  height: 9px;
  background: #010101;
  opacity: 0;
  transform: scale(0.5);
  transition: opacity 0.1s, transform 0.1s;
}
.field-group-checkbox input {
  position: absolute;
  left: -9999px;
}
.field-group-checkbox input:checked + .field-label:after {
  opacity: 1;
  transform: scale(1);
}

/*------------------------------------*\
		Pictogrammes
\*------------------------------------*/
.icon {
  line-height: 0;
}

.icon_sm.icon_hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  background: #fff;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}
.icon_sm.icon_hover svg {
  fill: #333;
}
.icon_sm,
.icon_sm svg {
  display: block;
  width: 4.93em;
  height: 0.79em;
}
.icon_sm svg {
  fill: #999;
}

/*------------------------------------*\
    Header and footer infos
\*------------------------------------*/
.header-footer-infos {
  padding: 1rem 0;
  font-family: "urwgrotesk-lig", "Helvetica Neue", Arial, sans-serif;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.404ch;
}
.header-footer-infos .col-title {
  margin-bottom: 1em;
}
.header-footer-infos .localisation-item {
  display: inline-block;
  vertical-align: middle;
}
.header-footer-infos .localisation-item:not(:last-child) {
  margin-right: 1em;
  padding-right: 1em;
}
.header-footer-infos .localisation-item:not(:last-child):after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: inline-block;
  width: 1px;
  height: 55%;
  margin-top: auto;
  margin-bottom: auto;
  vertical-align: middle;
  background: #cccccc;
}
@media (min-width: 30em) {
  .header-footer-infos {
    padding: 2.25rem 0;
  }
  .header-footer-infos .col-title {
    margin-bottom: 0;
  }
  .header-footer-infos .col-localisation {
    text-align: right;
  }
}
@media (min-width: 48em) {
  .header-footer-infos .localisation-item:not(:last-child) {
    margin-right: 2.5em;
    padding-right: 2.5em;
  }
}

/*------------------------------------*\
		Hero
\*------------------------------------*/
.hero {
  width: 100%;
  height: 20rem;
  color: #ffffff;
}
@media (min-width: 48em) {
  .hero {
    height: 42rem;
  }
}
@media (min-width: 80em) {
  .hero {
    height: 46rem;
  }
}

.hero__background--placeholder {
  transition: opacity 3s;
}

.hero__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cccccc;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.hero__background:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 23%, black 69%, black 100%);
  opacity: 0;
}
.hero__background.js-lazy.is-lazy-loaded:before {
  opacity: 0.75;
}
.hero__background.js-lazy.is-lazy-loaded ~ .hero__background--placeholder {
  opacity: 0;
}
@media (min-width: 80em) {
  .hero__background:before {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 23%, black 69%, black 100%);
  }
}

.hero__container {
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}

.hero__row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;
}
@media (min-width: 80em) {
  .hero__row {
    align-items: center;
  }
}

.hero__col {
  margin-bottom: 1em;
}
@media (min-width: 48em) {
  .hero__col {
    margin-bottom: 6em;
  }
}
@media (min-width: 80em) {
  .hero__col {
    margin-bottom: 0;
  }
}

.hero__surtitle {
  margin-top: 0;
  margin-bottom: 1em;
  font-family: "urwgrotesk-lig", "Helvetica Neue", Arial, sans-serif;
  font-size: 0.75em;
  line-height: 1.833333333em;
  text-transform: uppercase;
  letter-spacing: 0.404ch;
}

.hero__title {
  margin-top: 0;
  margin-bottom: 0;
  font-family: "paradox-st", Georgia, serif;
  font-size: 1.5em;
  font-weight: 400;
  line-height: 1.391304348em;
}
@media (min-width: 48em) {
  .hero__title {
    font-size: 1.75em;
  }
}
@media (min-width: 62em) {
  .hero__title {
    font-size: 2em;
  }
}
@media (min-width: 80em) {
  .hero__title {
    font-size: 2.25em;
  }
}
@media (min-width: 90em) {
  .hero__title {
    font-size: 2.875em;
  }
}

.hero__content {
  margin-top: 1em;
  margin-bottom: 0;
}
.hero__content p:first-child {
  margin-top: 0;
}
.hero__content p:last-child {
  margin-bottom: 0;
}

/*------------------------------------*\
		Share
\*------------------------------------*/
.share-item {
  display: inline-block;
  width: auto;
  padding-bottom: 0;
  background: transparent;
  color: #010101;
}
.share-item:focus, .share-item:hover {
  background: transparent;
  color: #4e4e4e;
}

/*==========================================================================*\
  Tooltip
\*==========================================================================*/
.tooltip__content {
  z-index: 1;
  position: absolute;
  bottom: calc(100% + 1em);
  left: 100%;
  display: none;
  background-color: #ffffff;
  border: 1px solid #eee;
  box-shadow: 0 0 22px -14px #000;
  transform: translateX(-50%);
}

.tooltip__button {
  z-index: 2;
  display: inline-flex;
  align-items: center;
  padding: 0;
  font-family: inherit;
  line-height: 0;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  outline: none;
  box-shadow: none;
}
.tooltip__button:hover ~ .tooltip__content, .tooltip__button:focus ~ .tooltip__content {
  display: block;
}

.tooltip-button__text {
  display: inline-block;
  font-size: 0.75em;
  line-height: 1.833333333em;
}

.tooltip-button__svg:first-child {
  margin-right: 0.5em;
}
.tooltip-button__svg:last-child {
  margin-left: 0.5em;
}