/*------------------------------------*\
		Buttons
\*------------------------------------*/

.btn {
  padding: 1.042em 1.75em;
  overflow: hidden;
  font-family: $font-grotesk;
  font-size: 0.75em;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  cursor: pointer;
  background: $black;
  border-radius: 0;
  transition: background 0.1s ease-in-out;

  &:hover,
  &:focus,
  &:active {
    background: $grey-dark;
    transition: background 0.1s ease-in-out;
  }

  .is-waiting & {
    background: #999;
    transition: background 0.1s ease-in-out;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 33%;
      height: 5px;
      background: $grey-light;
      animation: btnWaiting 0.75s linear infinite;
    }
  }
}

@keyframes btnWaiting {
  from {
    left: -33%;
  }

  to {
    left: 133%;
  }
}
