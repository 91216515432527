/*------------------------------------*\
		Fontface declaration
\*------------------------------------*/

// Variables
// Chemin relatif vers le dossier de fonts
$font-dir: "../fonts/" !default;

// Map contenant tous les noms de fichiers de la font
// avec leur graisse et style précisé.
$fonts: (
  "paradox-st" 400 normal,
  "urwgrotesk-lig" 400 normal,
);

// Loop that generate a font-face declaration
// for each file added to the `$fonts` map
@each $font in $fonts {
	@font-face {
		font-family: "#{nth($font, 1)}";
		src: url("#{$font-dir}#{nth($font, 1)}.woff") format("woff");
		src: url("#{$font-dir}#{nth($font, 1)}.woff2") format("woff2"),
				 url("#{$font-dir}#{nth($font, 1)}.woff") format("woff");
		font-weight: #{nth($font, 2)};
		font-style: #{nth($font, 3)};
	}
}

