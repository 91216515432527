/*
Typographie
===========

Règles de base pour tout le site (h1, h2, blockquote, etc).

-->    http://type-scale.com/    <--

Pratique pour générer des rythmes typographiques rapidement
*/

p,
ul,
ol,
blockquote {
	margin-bottom: 1.3em;
}

h1,
h2,
h3,
h4 {
  margin: 1.414em 0 0.5em;
  font-family: $font-paradox;
	font-weight: 400;
	line-height: 1.2;
}

h1 {
	margin-top: 0;
	font-size: 3.157em;
}

h2 {
	font-size: 2.369em;
}

h3 {
	font-size: 1.777em;
}

h4 {
	font-size: 1.333em;
}

small {
	font-size: 0.75em;
}

sup {
	font-size: 0.5em;
	vertical-align: super;
}

a {
	text-decoration-skip: ink;
}
