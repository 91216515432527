/*------------------------------------*\
		Header
\*------------------------------------*/

.header {
  padding-top: 1em;
  padding-bottom: 1em;

  @media #{md("normal")} {
    padding-top: 1.53em;
    padding-bottom: 1.53em;
  }
}

.header__row {
  @media #{md("large")} {
    display: flex;
    align-items: center;
  }
}

.header__col--title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.header-title__item {
  display: block;
  font-size: 0.625em;
  line-height: 1.375em;
  text-transform: uppercase;
  letter-spacing: $letter-spacing-200;

  @media #{md("smaller")} {
    display: inline-block;
  }
}

.header-title__item--text {
  // top: 0.225em;
}

.header-title__item--divider {
  display: block;
  width: 100%;
  height: 0.25em;

  @media #{md("smaller")} {
    display: inline-block;
    width: 1px;
    height: 1rem;
    margin-right: 2em;
    margin-left: 2em;
    background-color: $grey-medium;
  }
}

.header__col--logo {
  margin-top: 1.5em;
  line-height: 0;
  text-align: center;

  @media #{md('large')} {
    margin-top: 0;
  }
}

.header__logo {

}

.header__col--lang {

}

.header__lang {
  display: flex;
  align-items: center;
  line-height: 1em;

  a {
    font-size: 0.625em;
    line-height: 1em;
    color: $grey;
    text-transform: uppercase;
    letter-spacing: $letter-spacing-200;

    &.is-active {
      color: $grey-dark;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -0.5em;
        width: 100%;
        height: 1px;
        background-color: currentColor;
      }
    }

    &:not(:last-child) {
      margin-right: 1em;
    }
  }
}

.header__lang--mobile {
  width: 100%;
  justify-content: flex-end;

  @media #{md('smaller')} {
    width: auto;
    margin-left: auto;
    padding-left: 1em;
    justify-content: flex-start;
  }

  @media #{md('large')} {
    display: none;
  }
}

.header__lang--desktop {
  display: none;
  justify-content: flex-end;

  @media #{md('large')} {
    display: flex;
  }
}
