/*==========================================================================*\
  Aside
\*==========================================================================*/

@import 'settings/variables';
@import 'tools/functions';

/* Main
\*==========================================================================*/

.main-aside {
  z-index: z('goku');
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 50px);
  height: 100%;
  overflow: auto;
  background: #fff;
  transform: translateX(100%);
  transition: transform 0.4s;

  &.is-open {
    transform: translateX(0);

    + .aside-background-close {
      height: 100%;
      opacity: 0.8;
      transition: height 0s 0s, opacity 0.4s 0s;
    }
  }

  @media #{md('small')} {
    width: 75%;
  }

  @media #{md('normal')} {
    width: 50%;
  }

  @media #{md('large')} {
    width: 40%;
  }
}

.aside-close {
  z-index: z('default');
  position: absolute;
  top: 1em;
  right: 1em;
  line-height: 0;
  color: $black;
  cursor: pointer;
  background: #fff;

  &:hover {
    background: transparent;
  }
}

.aside-background-close {
  z-index: z('modal', 2);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  cursor: pointer;
  background: #000;
  opacity: 0;
  transition: height 0s 0.4s, opacity 0.4s 0s;
}

.main-aside-temp {
  display: none;
}
