/*==========================================================================*\
  Tooltip
\*==========================================================================*/

.tooltip {
}

.tooltip__content {
  z-index: 1;
  position: absolute;
  bottom: calc(100% + 1em);
  left: 100%;
  display: none;
  background-color: $white;
  border: 1px solid #eee;
  box-shadow: 0 0 22px -14px #000;
  transform: translateX(-50%);
}

.tooltip__button {
  z-index: 2;
  display: inline-flex;
  align-items: center;
  padding: 0;
  font-family: inherit;
  line-height: 0;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  outline: none;
  box-shadow: none;

  &:hover,
  &:focus {
    ~ .tooltip__content {
      display: block;
    }
  }
}

.tooltip-button__text {
  display: inline-block;
  font-size: 0.75em;
  line-height: 1.833333333em;
}

.tooltip-button__svg {
  &:first-child {
    margin-right: 0.5em;
  }

  &:last-child {
    margin-left: 0.5em;
  }
}

