/*------------------------------------*\
		Share
\*------------------------------------*/

.share-item {
	display: inline-block;
	width: auto;
	padding-bottom: 0;
	background: transparent;
	color: $black;

	&:focus,
	&:hover {
		background: transparent;
		color: lighten($black, 30%);
	}
}
