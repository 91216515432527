/*------------------------------------*\
    Header and footer infos
\*------------------------------------*/

.header-footer-infos {
  padding: 1rem 0;
  font-family: $font-grotesk;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: $letter-spacing-200;

  .col-title {
    margin-bottom: 1em;
  }

  .localisation-item {
    display: inline-block;
    vertical-align: middle;

    &:not(:last-child) {
      margin-right: 1em;
      padding-right: 1em;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: inline-block;
        width: 1px;
        height: 55%;
        margin-top: auto;
        margin-bottom: auto;
        vertical-align: middle;
        background: $grey-medium;
      }
    }
  }

  @media #{md('smaller')} {
    padding: 2.25rem 0;

    .col-title {
      margin-bottom: 0;
    }

    .col-localisation {
      text-align: right;
    }
  }

  @media #{md('small')} {
    .localisation-item {
      &:not(:last-child) {
        margin-right: 2.5em;
        padding-right: 2.5em;
      }
    }
  }
}
