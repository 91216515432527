/*------------------------------------*\
		Pictogrammes
\*------------------------------------*/

// Styles pour sprite PNG
$sprite-name: "sprite";
$sprite-size: 512px 512px;

%sprite-png {
	background-image: url("#{$img-dir}#{$sprite-name}.png");
	background-repeat: no-repeat;

	// And for the retina!
	@media #{md("retina")} {
		background-image: url("#{$img-dir}#{$sprite-name}@2x.png");
		background-size: $sprite-size;
	}
}

.icon {
  line-height: 0;
}

// Logo Studio Meta
.icon_sm {

	&.icon_hover {
		position: absolute;
		top: 0;
		left: 0;
		width: 0;

		background: #fff;
		overflow: hidden;
		transition: all 0.4s $out-expo;

		svg {
			fill: #333;
		}
	}

	&,
	svg {
		display: block;
		width: 4.93em;
		height: 0.79em;
	}

	svg {
		fill: #999;
	}
}
